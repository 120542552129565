<template>
  <div class="container">
    <PrimePreloader v-if="loading"/>

    <div v-else>
      <div class="row justify-content-center" v-if="userId">

        <div class="col-md-3">
          <div class="nav flex-column nav-pills mt-4" id="v-pills-tab" role="tablist"
               aria-orientation="vertical">

            <a class="nav-link active" id="v-pills-personal-tab" data-bs-toggle="pill"
               href="#v-pills-personal"
               role="tab" aria-controls="v-pills-personal" aria-selected="true"><i class="fa fa-user"
                                                                                   aria-hidden="true"></i>&nbsp;Личная
              информация</a>

            <a class="nav-link" id="v-pills-education-tab" data-bs-toggle="pill" href="#v-pills-education"
               role="tab"
               aria-controls="v-pills-education" aria-selected="false"><i class="fa fa-graduation-cap"
                                                                          aria-hidden="true"></i>&nbsp;Образование</a>

            <a class="nav-link" id="v-pills-science-degree-tab" data-bs-toggle="pill" href="#v-pills-science-degree"
               role="tab" aria-controls="v-pills-science-degree" aria-selected="false">
              <i class="fa fa-graduation-cap" aria-hidden="true"></i>
              <span class="ms-2">Научная степень</span>
            </a>

            <a class="nav-link" id="v-pills-job-tab" data-bs-toggle="pill" href="#v-pills-job" role="tab"
               aria-controls="v-pills-job" aria-selected="false"><i class="fa fa-briefcase"
                                                                    aria-hidden="true"></i>&nbsp; Опыт
              работы</a>


            <a class="nav-link" id="v-pills-teaching-tab" data-bs-toggle="pill"
               href="#v-pills-teaching" role="tab"
               aria-controls="v-pills-teaching" aria-selected="false"><i class="fa fa-briefcase" aria-hidden="true"></i>&nbsp;
              Опыт преподавания</a>

            <a class="nav-link" id="v-pills-accreditation-tab" data-bs-toggle="pill"
               href="#v-pills-accreditation" role="tab"
               aria-controls="v-pills-accreditation" aria-selected="false"><i class="fa fa-briefcase"
                                                                              aria-hidden="true"></i>&nbsp;
              Опыт прохождения аккредитации</a>

            <a class="nav-link" id="v-pills-grantswon-tab" data-bs-toggle="pill"
               href="#v-pills-grantswon" role="tab"
               aria-controls="v-pills-grantswon" aria-selected="false"><i class="fa fa-briefcase"
                                                                          aria-hidden="true"></i>&nbsp;
              Гранты</a>

            <a class="nav-link" id="v-pills-languageskills-tab" data-bs-toggle="pill"
               href="#v-pills-languageskills"
               role="tab" aria-controls="v-pills-languageskills" aria-selected="false"><i
              class="fa fa-globe"
              aria-hidden="true"></i>&nbsp;
              Языковые навыки</a>

            <a class="nav-link" id="v-pills-researchwork-tab" data-bs-toggle="pill"
               href="#v-pills-researchwork"
               role="tab" aria-controls="v-pills-researchwork" aria-selected="false"><i class="fa fa-book"
                                                                                        aria-hidden="true"></i>&nbsp;
              НИР</a>

            <a class="nav-link" id="v-pills-warn-tab" data-bs-toggle="pill"
               href="#v-pills-warn"
               role="tab" aria-controls="v-pills-warn" aria-selected="false"><i class="fa fa-exclamation-circle"
                                                                                        aria-hidden="true"></i>&nbsp;
              Замечания</a>
          </div>
        </div>
        <div class="col-md-9">
          <div class="tab-content" id="v-pills-tabContent">
            <div class="tab-pane fade show active" id="v-pills-personal" role="tabpanel"
                 aria-labelledby="v-pills-personal-tab">
              <QuestionnaireInfo/>
            </div>
            <div class="tab-pane fade" id="v-pills-education" role="tabpanel"
                 aria-labelledby="v-pills-education-tab">
              <Education/>
            </div>
            <div class="tab-pane fade" id="v-pills-science-degree" role="tabpanel"
                 aria-labelledby="v-pills-job-tab">
              <ScienceDegree/>
            </div>
            <div class="tab-pane fade" id="v-pills-job" role="tabpanel"
                 aria-labelledby="v-pills-job-tab">
              <Job/>
            </div>
            <div class="tab-pane fade" id="v-pills-teaching" role="tabpanel"
                 aria-labelledby="v-pills-teaching-tab">
              <UserTeaching/>
            </div>

            <div class="tab-pane fade" id="v-pills-accreditation" role="tabpanel"
                 aria-labelledby="v-pills-accreditation-tab">
              <AccreditationTab/>
            </div>

            <div class="tab-pane fade" id="v-pills-grantswon" role="tabpanel"
                 aria-labelledby="v-pills-grantswon-tab">
              <UserGrants/>
            </div>
            <div class="tab-pane fade" id="v-pills-languageskills" role="tabpanel"
                 aria-labelledby="v-pills-languageskills-tab">
              <LanguageSkills/>
            </div>
            <div class="tab-pane fade" id="v-pills-researchwork" role="tabpanel"
                 aria-labelledby="v-pills-researchwork-tab">
              <ResearchWorks/>
            </div>
            <div class="tab-pane fade" id="v-pills-warn" role="tabpanel"
                 aria-labelledby="v-pills-warn-tab">
              <Warns/>
            </div>
          </div>
        </div>


      </div>
      <div class="text-center mt-3" v-else>Пользователь не выбран</div>
    </div>


  </div>
</template>

<script>
import QuestionnaireInfo from '@/components/vacancy-resume-info/questionnaire/QuestionnaireInfo.vue'
import Education from '@/components/vacancy-resume-info/education/Education.vue'
import ScienceDegree from '@/components/vacancy-resume-info/science-degree/ScienceDegree.vue'
import UserTeaching from '@/components/vacancy-resume-info/teaching/UserTeaching.vue'
import UserGrants from '@/components/vacancy-resume-info/grants/UserGrants.vue'
import Job from '@/components/vacancy-resume-info/job/Job.vue'
import LanguageSkills from '@/components/vacancy-resume-info/language-skills/LanguageSkills.vue'
import ResearchWorks from '@/components/vacancy-resume-info/research-work/ResearchWorks.vue'
import Warns from '@/components/vacancy-resume-info/warns/Warns.vue'
import {mapActions, mapState} from "vuex";
import AccreditationTab from "@/components/vacancy-resume-info/accreditation/AccreditationTab.vue";

export default {
  name: 'UserInfo',
  components: {
    QuestionnaireInfo,
    Education,
    ScienceDegree,
    Job,
    UserTeaching,
    UserGrants,
    LanguageSkills,
    ResearchWorks,
    Warns,
    AccreditationTab
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState('userinfo', ['userId'])
  },
  methods: {
    ...mapActions('userinfo', ['GET_USER_PERSONAL_DATA', 'GET_USER_ADDRESS_DATA', 'GET_USER_CHILDREN_DATA',
      'GET_USER_EDUCATION_DATA', 'GET_USER_QUALIFICATION_DATA', 'GET_USER_EDUCATION_FILES',
      'GET_USER_JOB_DATA', 'GET_USER_LANG_DATA', 'GET_USER_ARTICLES', 'GET_SCIENCE_DEGREE_BY_USER']),
    ...mapActions('useraddress', ['getCity']),
    ...mapActions('personal', ['getNationalities', 'getGenders', 'getMaritalStatuses']),
    ...mapActions('document', ['getCitizenships', 'getDocTypes']),
    ...mapActions('education', ['getLanguages']),
    ...mapActions('languageskills', ['GET_LANGUAGES', 'GET_LANGUAGES_LEVEL', 'GET_LANGUAGE_SKILLS_DATA', 'GET_LANGUAGE_CERTIFICATION_TYPES']),
    ...mapActions('researchwork', ["GET_RESEARCH_WORK_TYPE", "GET_SUB_RESEARCH_WORK_TYPE"]),
    ...mapActions('professionalCertification', ['GET_PROFESSIONAL_CERTIFICATION_DATA', 'GET_EDUCATION_GROUPS', 'GET_CERTIFICATION_CENTER']),
    ...mapActions('accreditationExperience', ['GET_ACCREDITATION_TYPES', 'GET_ACCREDITATION_ROLES', 'GET_ACCREDITATION_EXPERIENCE', 'GET_ACCREDITATION_AGENCIES']),

  },
  async mounted() {
    let userId = this.userId
    console.log('userId', userId)

    if (userId) {
      await this.getCity()

      await this.getNationalities()
      await this.getGenders()
      await this.getMaritalStatuses()

      await this.getCitizenships()
      await this.getDocTypes()

      await this.getLanguages()

      await this.GET_LANGUAGES()
      await this.GET_LANGUAGES_LEVEL()
      await this.GET_LANGUAGE_SKILLS_DATA()

      await this.GET_RESEARCH_WORK_TYPE()
      await this.GET_SUB_RESEARCH_WORK_TYPE()

      await this.GET_USER_PERSONAL_DATA(userId)
      await this.GET_USER_ADDRESS_DATA(userId)
      await this.GET_USER_CHILDREN_DATA(userId)
      await this.GET_USER_EDUCATION_DATA(userId)
      await this.GET_USER_QUALIFICATION_DATA(userId)
      await this.GET_USER_EDUCATION_FILES(userId)
      await this.GET_USER_JOB_DATA(userId)
      await this.GET_USER_LANG_DATA(userId)
      await this.GET_USER_ARTICLES(userId)
      await this.GET_SCIENCE_DEGREE_BY_USER(userId)

      await this.GET_PROFESSIONAL_CERTIFICATION_DATA(userId)
      await this.GET_EDUCATION_GROUPS()
      await this.GET_CERTIFICATION_CENTER()

      await this.GET_ACCREDITATION_AGENCIES()
      await this.GET_ACCREDITATION_TYPES()
      await this.GET_ACCREDITATION_ROLES()
      await this.GET_ACCREDITATION_EXPERIENCE(userId)

      await this.GET_LANGUAGE_CERTIFICATION_TYPES()

    }

    this.loading = false
  }
}
</script>
