<template>

    <div class="address-form mt-4">

        <!-- Адрес прописки -->
        <h4 class="text-center">Адрес прописки</h4>

        <div class="mt-4 mb-1">
            <RowTwoColumns name="Город" :value="residence_city"/>
            <RowTwoColumns name="Улица" :value="addressData.residence_street"/>
            <RowTwoColumns name="Дом" :value="addressData.residence_home"/>
            <RowTwoColumns name="Квартира" :value="addressData.residence_apartment"/>
        </div>
        <!-- END Адрес прописки -->


        <!-- Адрес проживания -->
        <h4 class="text-center">Адрес проживания</h4>

        <div class="mt-4 mb-1">
            <RowTwoColumns name="Город" :value="inhabitation_city"/>
            <RowTwoColumns name="Улица" :value="addressData.inhabitation_street"/>
            <RowTwoColumns name="Дом" :value="addressData.inhabitation_home"/>
            <RowTwoColumns name="Квартира" :value="addressData.inhabitation_apartment"/>
        </div>
        <!-- END Адрес проживания -->

    </div>

</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import RowTwoColumns from "@/components/common/RowTwoColumns"

    export default {
        name: 'Address',
        components: {RowTwoColumns},
        computed: {
            ...mapGetters('useraddress', ['allCities']),
            ...mapState('userinfo', ['addressData']),
            residence_city() {
                let residence_city = this.allCities.find(i=>i.id == this.addressData.residence_city)
                if (!residence_city) {
                    return ''
                }
                return residence_city.name
            },
            inhabitation_city() {
                let inhabitation_city = this.allCities.find(i=>i.id == this.addressData.inhabitation_city)
                if (!inhabitation_city) {
                    return ''
                }
                return inhabitation_city.name
            },
        }
    }

</script>

<style scoped>

</style>