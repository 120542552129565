<template>

    <div class="document-form mt-4">

        <!-- Документ -->
        <h4 class="text-center">Документ</h4>

        <div class="col-md-9 mt-4 mb-1">

            <RowTwoColumns v-if="personalData.citizenship" name="Гражданство" :value="citizenship"/>
            <RowTwoColumns name="Тип документа" :value="doc_type"/>
            <RowTwoColumns name="Номер документа" :value="personalData.doc_number"/>
            <RowTwoColumns name="ИИН" :value="personalData.doc_iin" v-if="personalData.citizenship === 1"/>
            <RowTwoColumns name="Орган выдачи" :value="personalData.doc_issued"/>
            <RowTwoColumns name="Дата выдачи документа" :value="convertTimestampToDate(personalData.doc_date_start)"/>
            <RowTwoColumns name="Срок действия документа" :value="convertTimestampToDate(personalData.doc_date_end)"/>
            <RowTwoColumns v-if="visa_status == true" name="Срок действия визы" :value="personalData.doc_visa"/>

        </div>
        <!-- END Документ -->

        <!--
                <div class="form-group row mb-4" v-if="citizenship === 2">
                    <label class="col-md-3 col-form-label">Имеется ли виза?</label>
                    <div class="col-md-9">
                        <input type="checkbox" id="visa_status" v-model="visa_status"/>
                    </div>
                </div>

                <div class="form-group row mb-4" v-if="visa_status == true">
                    <label class="col-md-3 col-form-label">Срок действия визы</label>
                    <div class="col-md-9">
                        <input type="date" class="form-control" placeholder="Срок действия визы" v-model="doc_visa">
                    </div>
                </div>

                -->

    </div>


</template>

<script>

    import {mapGetters, mapState} from "vuex"
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
    import RowTwoColumns from "@/components/common/RowTwoColumns"

    export default {
        name: 'Document',
        components: {RowTwoColumns},
        computed: {
            ...mapGetters('document', ['allCitizenships', 'allDocTypes', 'allDocIssuedes']),
            ...mapState('userinfo', ['personalData']),
            getAllCitizenships() {
                return [{id: 0, name_ru: 'Выберите гражданство'}, ...this.allCitizenships]
            },
            getAllDocTypes() {
                return this.allDocTypes
            },
            citizenship() {
                let citizenship = this.getAllCitizenships.find(i => i.id == this.personalData.citizenship)
                if (!citizenship) {
                    return ''
                }
                return citizenship.name_ru
            },
            doc_type() {
                let doc_type = this.getAllDocTypes.find(i => i.id == this.personalData.doc_type)
                if (!doc_type) {
                    return ''
                }
                return doc_type.name_ru
            }
        },
        methods: {
            convertTimestampToDate,
        },
    }

</script>

<style scoped>

</style>