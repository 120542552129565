<template>
  <div>
    <PrimePreloader v-if="loading"/>

    <div v-else class="row">
      <nav>
        <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">
          <button class="nav-link active" id="nav-foreigneducationalgrant-tab" data-bs-toggle="tab"
                  data-bs-target="#nav-foreigneducationalgrant"
                  type="button" role="tab" aria-controls="nav-foreigneducationalgrant" aria-selected="true"><i
            class="fa fa-graduation-cap" aria-hidden="true"></i>&nbsp; Зарубежные образовательные гранты
          </button>
          <button class="nav-link" id="nav-researchgrant-tab" data-bs-toggle="tab" data-bs-target="#nav-researchgrant"
                  type="button" role="tab" aria-controls="nav-researchgrant" aria-selected="false"><i
            class="fa fa-line-chart" aria-hidden="true"></i>&nbsp; Научно-исследовательские гранты
          </button>
        </div>
      </nav>

      <div class="form-block">

        <div class="tab-content" id="nav-tabContent">

          <div class="tab-pane fade show active" id="nav-foreigneducationalgrant" role="tabpanel"
               aria-labelledby="nav-foreigneducationalgrant-tab">
            <div class="my-3">
              <h4 class="text-center">Зарубежные образовательные гранты</h4>

              <div class="border rounded-3 p-3 my-2"
                   v-for="(foreignEducationalGrant, index) in teacherForeignEducationalGrants"
                   :key="index">
                <h5>{{ foreignEducationalGrant.training_program_name }}</h5>
                <div class="my-1">
                  {{foreignEducationalGrant.organizer_name}}, {{foreignEducationalGrant.organization_name}}
                </div>
                <div class="my-1">
                  {{ foreignEducationalGrant.start_date }} - {{ foreignEducationalGrant.end_date }}
                </div>
                <div>
                  <span v-if="foreignEducationalGrant.sp_teacher_grant_type_id"
                        class="bg-primary text-light rounded-3 px-2 py-1 me-2">
                    {{ grantsTypes.find(i=>i.id==foreignEducationalGrant.sp_teacher_grant_type_id)?.name }}
                  </span>
                  <span v-if="foreignEducationalGrant.study_level_id"
                        class="bg-secondary text-light rounded-3 px-2 py-1">
                    {{ studyLevels.find(i=>i.id==foreignEducationalGrant.study_level_id)?.name }}
                  </span>
                </div>

              </div>


            </div>
          </div>

          <div class="tab-pane fade" id="nav-researchgrant" role="tabpanel"
               aria-labelledby="nav-researchgrant-tab">
            <div class="my-3">
              <h4 class="text-center">Научно-исследовательские гранты</h4>

              <div class="border rounded-3 p-3 my-2"
                   v-for="(grant, index) in researchGrants"
                   :key="index">
                <h5>{{ grant.job_title }}</h5>
                <div class="my-1">
                  {{grant.institution_receiving}}
                </div>
                <div class="my-1">{{ grant.start_date }} - {{ grant.end_date }}</div>
                <div v-if="grant.description_result" class="my-2">
                  {{ grant.description_result }}
                </div>
                <div>
                <span v-if="grant.participation_id" class="bg-secondary text-light rounded-3 px-2 py-1 me-2">
                  {{ formsOfParticipation.find(i=>i.id==grant.participation_id)?.name }}
                </span>
                </div>

              </div>


            </div>

          </div>

        </div>

      </div>
    </div>
  </div>

</template>

<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"

  export default {
    name: 'UserGrants',
    data() {
      return {
        loading: true,
        teacherForeignEducationalGrants: [],
        grantsTypes: [],
        researchGrants: [],
      }
    },
    computed: {
      ...mapState('userinfo', ['userId']),
      ...mapState('teacherGrants', ['formsOfParticipation']),
      ...mapState('educationdiscipline', ['educationdiscipline_form']),
      studyLevels() {
        return this.educationdiscipline_form.studyLevel
      }
    },
    methods: {
      ...mapActions('teacherGrants', [
        'GET_TEACHER_GRANT_TYPE', 'GET_FOREIGN_EDUCATIONAL_GRANTS',
        'GET_FORMS_OF_PARTICIPATION', 'GET_RESEARCH_GRANTS'
      ]),
      ...mapActions('educationdiscipline', ['GET_STUDY_LEVEL_NAMES']),
    },
    async mounted() {
      let user_id = this.userId

      if (user_id) {
        await this.GET_STUDY_LEVEL_NAMES()
        this.grantsTypes = await this.GET_TEACHER_GRANT_TYPE()
        this.teacherForeignEducationalGrants = await this.GET_FOREIGN_EDUCATIONAL_GRANTS(user_id)

        await this.GET_FORMS_OF_PARTICIPATION()
        this.researchGrants = await this.GET_RESEARCH_GRANTS(user_id)
      }
      this.loading = false
    }
  }
</script>