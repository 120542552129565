<template>
  <div>
    {{ warns }}
  </div>
</template>


<script>

import {mapState, mapMutations, mapActions} from "vuex";

export default {
  name: 'Warns',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState('personal', ['warns'])
  },
  methods: {
    ...mapActions('personal', ['getWarns'])
  },
  async mounted(){
    await this.getWarns()
  }
}

</script>

<style scoped>

</style>