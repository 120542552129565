<template>

    <div class=" personal-form mt-4">

        <h4 class="text-center">Личные данные</h4>
      <div class="text-center mb-4" v-if="personalData.photo">
        <img width="100" class="rounded-3" :src="'data:image/png;base64,'+personalData.photo"/>
      </div>
        <div>
            <RowTwoColumns name="Фамилия" :value="personalData.lastname"/>
            <RowTwoColumns name="Имя" :value="personalData.firstname"/>
            <RowTwoColumns name="Отчество" :value="personalData.middlename"/>
            <RowTwoColumns name="Место рождения" :value="personalData.birth_place"/>
            <RowTwoColumns name="Дата рождения" :value="convertTimestampToDate(personalData.birth_date)"/>
            <RowTwoColumns name="Телефон" :value="personalData.phone_number"/>
            <RowTwoColumns name="Домашний телефон" :value="personalData.home_phone_number"/>
            <RowTwoColumns name="Пол" :value="personalData.sex==1?'Мужской':'Женский'"/>
            <RowTwoColumns name="Национальность" :value="nationality"/>
            <RowTwoColumns name="Семейное положение" :value="marital_status"/>
            <RowTwoColumns name="Отношение к воинской обязанности" :value="personalData.military_status==1?'Да':'Нет'"/>
            <RowTwoColumns name="Отметки о судимости" :value="personalData.conviction_status==1?'Да':'Нет'"/>
            <RowTwoColumns name="Готовность к выездам в служебные командировки"
                           :value="personalData.business_trip_status==1?'Да':'Нет'"/>
            <RowTwoColumns name="Пенсионер" :value="personalData.retiree_status==1?'Да':'Нет'"/>
        </div>

    </div>

</template>

<script>

    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
    import {mapGetters, mapState} from "vuex"
    import RowTwoColumns from "@/components/common/RowTwoColumns"


    export default {
        name: 'Personal',
        components: {RowTwoColumns},
        computed: {
            ...mapState('userinfo', ['personalData']),
            ...mapGetters('personal', ['allNationalities', 'allGenders', 'allMaritalStatuses']),
            nationality() {
                let nationality = this.allNationalities.find(i => i.id == this.personalData.nationality)
                if (!nationality) {
                    return ''
                }
                return nationality.name_ru
            },
            marital_status() {
                let marital_status = this.allMaritalStatuses.find(i => i.id == this.personalData.marital_status)
                if (!marital_status) {
                    return ''
                }
                return marital_status.name
            }

        },
        methods: {
            convertTimestampToDate
        },
    }

</script>

<style scoped>

</style>