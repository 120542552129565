<template>
  <div class="professional-certification-form mt-4">
    <h4 style="text-align: center">Профессиональная сертификация</h4>

    <div class="form-row"
         v-for="(professionalCertificationInfo, index) in professionalCertification_form.professionalCertificationInfos"
         :key="index">

      <RowTwoColumns name="Центр сертификации"
                     :value="professionalCertification_form.certificationCenter.find(item=>item.id == professionalCertificationInfo.certification_center_id)?.name"/>

      <RowTwoColumns name="Профессиональная область"
                     :value="professionalCertification_form.educationGroups.find(item=>professionalCertificationInfo.education_groups_id == item.id)?.code+' '+professionalCertification_form.educationGroups.find(item=>professionalCertificationInfo.education_groups_id == item.id)?.name"/>

      <RowTwoColumns name="Срок действия сертификата"
                     :value="professionalCertificationInfo.is_perpetual == 0?convertTimestampToDate(professionalCertificationInfo.expiry_date):'Бессрочный'"/>

      <div class="form-group row mt-4">
        <label for="file" class="col-md-3 col-form-label">Файл сертификата</label>
        <div class="col-md-9">
          <div class="my-3">
            <a :href="'https://back.uib.kz/uploads/' + professionalCertificationInfo.file">
              {{ professionalCertificationInfo.file }} </a>
          </div>
        </div>
      </div>


      <hr>

    </div>

  </div>


</template>

<script>
import {mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/date.helpers"
import RowTwoColumns from "@/components/common/RowTwoColumns.vue";

export default {
  name: "ProfessionalCertificationTab",
  components: {RowTwoColumns},
  computed: {
    ...mapState('professionalCertification', ['professionalCertification_form']),
  },
  methods: {
    convertTimestampToDate,
  },
}
</script>

<style scoped>

</style>
