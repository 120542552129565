<template>

    <div class="children-form mt-4">
        <h4 class="text-center">Дети</h4>
        <div v-if="childrenData.length">

            <hr>
            <div v-for="(childrenInfo, index) in childrenData" :key="index">
                <RowTwoColumns name="Дата рождения" :value="convertTimestampToDate(childrenInfo.birth_date)"/>
                <RowTwoColumns name="Пол" :value="childrenInfo.gender==1?'Мужской':'Женский'"/>
                <hr>
            </div>

        </div>
    </div>


</template>

<script>
    import {mapState} from "vuex"
    import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
    import RowTwoColumns from "@/components/common/RowTwoColumns"

    export default {
        name: "Children",
        components: {RowTwoColumns},
        computed: {
            ...mapState('userinfo', ['childrenData']),
        },
        methods: {
            convertTimestampToDate,
        }
    }
</script>

<style scoped>

</style>