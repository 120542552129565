<template>

    <div class="education-file-form mt-4 mb-4">
        <h4 class="text-center">Документы об образовании</h4>

        <div v-if="educationFileLinks.length">
            <hr>
            <div v-for="(educationFileLink, index) in educationFileLinks" :key="index">
                <div class="mt-3">
                    <a :href="'https://back.uib.kz/uploads/' + educationFileLink.file">{{ educationFileLink.name }}</a>
                </div>

                <hr>
            </div>
        </div>

    </div>

</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "EducationFileTab",
        computed: {
            ...mapState('userinfo', ['educationFileLinks']),
        }
    }

</script>

<style scoped>
</style>