<template>

    <div class="row">
        <nav>
            <div class="nav nav-tabs mt-4" id="nav-tab" role="tablist">

                <button class="nav-link active" id="nav-personal-tab" data-bs-toggle="tab"
                        data-bs-target="#nav-personal"
                        type="button" role="tab" aria-controls="nav-personal" aria-selected="false"><i
                        class="fa fa-user" aria-hidden="true"></i>&nbsp; Личные данные
                </button>
                <button class="nav-link" id="nav-document-tab" data-bs-toggle="tab" data-bs-target="#nav-document"
                        type="button" role="tab" aria-controls="nav-document" aria-selected="false"><i
                        class="fa fa-file-text" aria-hidden="true"></i>&nbsp; Документ
                </button>
                <button class="nav-link" id="nav-address-tab" data-bs-toggle="tab" data-bs-target="#nav-address"
                        type="button" role="tab" aria-controls="nav-address" aria-selected="false"><i
                        class="fa fa-map-marker" aria-hidden="true"></i>&nbsp; Адрес
                </button>
                <button class="nav-link" id="nav-children-tab" data-bs-toggle="tab" data-bs-target="#nav-children"
                        type="button" role="tab" aria-controls="nav-children" aria-selected="false"><i
                        class="fas fa-child" aria-hidden="true"></i>&nbsp; Дети
                </button>


            </div>
        </nav>

        <div class="form-block">

            <div class="tab-content" id="nav-tabContent">

                <div class="tab-pane fade show active" id="nav-personal" role="tabpanel"
                     aria-labelledby="nav-personal-tab">
                    <Personal/>
                </div>

                <div class="tab-pane fade" id="nav-document" role="tabpanel"
                     aria-labelledby="nav-document-tab">
                    <Document/>
                </div>

                <div class="tab-pane fade" id="nav-address" role="tabpanel"
                     aria-labelledby="nav-address-tab">
                    <Address/>
                </div>

                <div class="tab-pane fade" id="nav-children" role="tabpanel"
                     aria-labelledby="nav-children-tab">
                    <Children/>
                </div>


            </div>

        </div>
    </div>
</template>

<script>

    import Address from '@/components/vacancy-resume-info/questionnaire/Address.vue'
    import Document from '@/components/vacancy-resume-info/questionnaire/Document.vue'
    import Personal from '@/components/vacancy-resume-info/questionnaire/Personal.vue'
    import Children from '@/components/vacancy-resume-info/questionnaire/Children.vue'

    export default {
        name: 'QuestionnaireInfo',
        components: {
            Address,
            Document,
            Personal,
            Children
        }
    }
</script>