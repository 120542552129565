<template>
  <div class="mt-4">
    <h4 style="text-align: center">Опыт в составлении отчета по самооценке</h4>

    <div class="form-row mt-4" v-for="(accreditation_experience_info, index) in accreditation_experience" :key="index">

      <RowTwoColumns name="Вид прохождения аккредитации"
                     :value="accreditation_types.find(itemAccreditationType=>itemAccreditationType.id == accreditation_experience_info.accreditation_type_id)?.name"/>

      <RowTwoColumns name="Аккредитационное агентство"
                     :value="accreditationAgencies.find(itemAccreditationAgency=>itemAccreditationAgency.id == accreditation_experience_info.accreditation_agencies_id)?.name"/>

      <RowTwoColumns name="Роль"
                     :value="accreditation_roles.find(itemAccreditationRole=>itemAccreditationRole.id == accreditation_experience_info.accreditation_role_id)?.name"/>

      <RowTwoColumns v-if="accreditation_experience_info.accreditation_type_id == 1"
                     name="Название образовательной программы"
                     :value="accreditation_experience_info.education_program_name"/>
      <hr>

    </div>


  </div>
</template>

<script>
import {mapState} from "vuex";
import RowTwoColumns from "@/components/common/RowTwoColumns.vue";

export default {
  name: "AccreditationTab",
  components: {RowTwoColumns},
  computed: {
    ...mapState('accreditationExperience', ['accreditation_types', 'accreditation_roles', 'accreditation_experience', 'accreditationAgencies']),
  },
}
</script>

<style scoped>

</style>
