<template>
  <div>
    <PrimePreloader v-if="loading"/>

    <div v-else>
      <h4 class="text-center my-4">Опыт преподавания</h4>


      <div class="my-3">

        <div class="mb-4">
          <div v-for="(discipline, disciplineKey) in teacherCoursesFront" :key="disciplineKey"
               class="border rounded-3 p-2 my-3">
            <h5 class="mb-2">
              {{ discipline[0].education_discipline_name }}
            </h5>
            <div v-for="(item, index) in discipline" :key="index" class="mb-2">
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.academic_year_name }}</span>
              <span v-if="item.moodle_course" class="px-2 py-1 me-2">
              <a :href="item.moodle_course" class="text-decoration-none" target="_blank">
                {{item.course_name}}
              </a>
            </span>
              <span v-if="item.file_url">
                Силлабус:
                <a
                  :href="item.file_url.startsWith('https://back.uib.kz')?item.file_url:'https://back.uib.kz/uploads/'+item.file_url"
                  target="_blank">
                  <i class="pi pi-file-pdf text-danger"></i>
                </a>
            </span>
            </div>
            <div class="mt-3">
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ discipline[0].study_level_name }}</span>
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ discipline[0].language_name }}</span>
            </div>
          </div>
        </div>


        <div>
          <div v-for="(item, index) in teacherCourses" :key="index" class="border rounded-3 p-2 my-3">
            <h5>{{ item.discipline_name }}</h5>

            <div class="mb-2">
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.academic_year_name }}</span>
              Силлабус:
              <a :href="item.syllabus_url" target="_blank">
                <i class="pi pi-file-pdf text-danger"></i>
              </a>
            </div>
            <div>
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.study_level_name }}</span>
              <span class="bg-light rounded-3 px-2 py-1 me-2">{{ item.language_name }}</span>
              <span>Количество лет преподавания: {{ item.teaching_years }}</span>
            </div>
          </div>

        </div>


      </div>

    </div>
  </div>
</template>


<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: "UserTeaching",
    data() {
      return {
        loading: true,
        teacherCoursesFront: [],
        teacherCourses: [],
      }
    },
    computed: {
      ...mapState('userinfo', ['userId'])
    },
    methods: {
      ...mapActions('teachingExperiences', ['GET_TEACHING_EXPERIENCES', 'GET_TEACHING_EXPERIENCES_FRONT']),
    },
    async mounted() {
      let user_id = this.userId

      if (user_id) {
        this.teacherCourses = await this.GET_TEACHING_EXPERIENCES(user_id)
        this.teacherCoursesFront = await this.GET_TEACHING_EXPERIENCES_FRONT(user_id)
      }
      this.loading = false
    }
  }

</script>

<style scoped>

</style>

